import { object, string, number, array, boolean, addMethod } from "yup";

const VALIDATE_ACTIVITY_NAME = /[^\d\w]+?/g;

const TYPE_NAME_REQUIRED = "Type name is required";
const CATEGORY_REQUIRED = "Category is required";
const HOURS_TO_COMPLETE_REQUIRED = "Hours to complete work is required";
const HOURS_ONLY_NUMBER = "Hours only a number";
const TIME_PERIOD_REQUIRED = "Time period is required";
const REPEATATION_PERIOD_REQUIRED = "Repetation period is required";
const ASSIGNEE_REQUIRED = "Assignee is required";
const STANDARD_FEES_REQUIRED = "Standard fees is required";
const FIXED_DATE_REQUIRED = "Fixed date is required";
const IDEAL_DAYS_REQUIRED = "Ideal days is required";
const TASK_ONBOARD_DAYS_REQUIRED = "Task onboard days is required";
const ACTIVITY_NAME_REQUIRED = "Activity name is required";
const FIELD_TYPE_REQUIRED = "Field type is required";
const FIELD_NAME_REQUIRED = "Field name is required";
const MINIMUM_VALUE_REQUIRED = "Minimum value is required";
const FIELD_NAME_PATTERN =
  "Field name does not contain any special character and space";

addMethod(string, "doesNotContainSpecialCharacter", function () {
  return this.test(
    "doesNotContainSpecialCharacter",
    "",
    async function (value: any) {
      const { path, createError } = this;

      if (value) {
        if (VALIDATE_ACTIVITY_NAME.test(value)) {
          return createError({
            path,
            message: FIELD_NAME_PATTERN,
          });
        }
      }
      return true;
    }
  );
});

export const tasktypeValidateSchema = object().shape({
  name: string().required(TYPE_NAME_REQUIRED),
  categoryId: number().positive(CATEGORY_REQUIRED).required(CATEGORY_REQUIRED),
  hoursToComplete: number()
    .required(HOURS_TO_COMPLETE_REQUIRED)
    .typeError(HOURS_ONLY_NUMBER)
    .moreThan(-1, "Value must be positive.")
    .integer("Value must be an integer."),
  isRepeatative: string().required(TIME_PERIOD_REQUIRED),
  repeatation: string().when("isRepeatative", {
    is: (isRepeatative: string) => isRepeatative === "true",
    then: string().required(REPEATATION_PERIOD_REQUIRED),
  }),
  // defaultAssigneeId: string().required(ASSIGNEE_REQUIRED),
  // defaultManagerId: string().required("Manager is required"),
  // defaultExpertId: string().required("Expert is required"),
  standardFees: number().required(STANDARD_FEES_REQUIRED),
  governmentFixDate: string().when("isRepeatative", {
    is: "true",
    then: string().required(FIXED_DATE_REQUIRED),
  }),
  idealDaysToComplete: number().when("isRepeatative", {
    is: "false",
    then: number()
      .required(IDEAL_DAYS_REQUIRED)
      .integer("Value must be an integer.")
      .moreThan(-1, "Value must be positive."),
  }),
  onboardBefore: number()
    .required(TASK_ONBOARD_DAYS_REQUIRED)
    .integer("Value must be an integer.")
    .moreThan(-1, "Value must be positive."),
  activities: array().of(
    object().shape({
      name: string().required(ACTIVITY_NAME_REQUIRED),
      requireCompletionField: boolean(),
      completionFieldType: string().when("requireCompletionField", {
        is: (requireCompletionField: boolean) => requireCompletionField,
        then: string().required(FIELD_TYPE_REQUIRED),
        otherwise: string().nullable(),
      }),
      completionFieldName: string().when("requireCompletionField", {
        is: (requireCompletionField: boolean) => requireCompletionField,
        then: (string() as any)
          .required(FIELD_NAME_REQUIRED)
          .doesNotContainSpecialCharacter(),
        otherwise: string().nullable(),
      }),
      minValue: number().when("completionFieldType", {
        is: (completionFieldType: string) => completionFieldType === "number",
        then: number().required(MINIMUM_VALUE_REQUIRED),
        otherwise: number().nullable(),
      }),
    })
  ),
});
