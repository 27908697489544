import { KTSVG } from "_metronic/helpers";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { convert } from "app/helpers/helperFunction";
import { PROFORMA_INVOICE_LIST } from "app/helpers/routes";
import { FC, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { GeneratePdfByClick } from "../../billing/component/GetUrlForPdf";

interface props {
  row: any;
  setShowBillEditForm: React.Dispatch<
    SetStateAction<{ show: boolean; billId: number | undefined }>
  >;
}

const ProformaInvoiceTableRow: FC<props> = ({ row, setShowBillEditForm }) => {
  const navigate = useNavigate();

  const navigateToDetailPage = () => {
    navigate(`${PROFORMA_INVOICE_LIST}/${row.Id}`);
  };

  return (
    <tr>
      <td className="text-dark fw-bold ps-2">{row.BillNumber}</td>
      <td className="">{row.ClientName}</td>
      <td className="">
        {row.Tasks.map((task: { TaskCode: string }) => task.TaskCode).join(
          ","
        ) || "-"}
      </td>
      <td className="">{row?.Firm?.Name || "-"}</td>
      <td className="">{row.TotalAmount}</td>
      <td>{row?.Date ? convert(row.Date) : "-"}</td>
      <td>
        <div className={`badge badge-sm bill-status-${row.PaymentStatus}`}>
          {PAYMENT_STATUS[row.PaymentStatus]}
        </div>
      </td>
      <td>
        <div className="d-flex gap-3 justify-content-end pe-5">
          <button
            className={`p-2 btn btn-icon btn-bg-light btn-sm btn-active-light-primary`}
            title="Edit"
            onClick={() => setShowBillEditForm({ show: true, billId: row.Id })}
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          <GeneratePdfByClick
            billData={row}
            title="Proforma Invoice"
            className="btn btn-icon btn-bg-light btn-sm btn-active-light-primary"
            showIcon={true}
          />
        </div>
      </td>
      <td className="pe-5">
        <button
          className={`btn btn-icon btn-bg-light btn-light btn-sm btn-active-light-primary`}
          title="Invoice Detail"
          onClick={navigateToDetailPage}
        >
          <KTSVG
            path="/media/icons/duotune/arrows/arr064.svg"
            className="svg-icon-2 svg-icon-primary"
          />
        </button>
      </td>
    </tr>
  );
};

export default ProformaInvoiceTableRow;
