import { addUserData, searchParams } from "../../helpers/commonInterface";
import {
  showToastMessageFailure,
  showToastMessageSuccess,
} from "../../helpers/helperFunction";
import { failure, request, success } from "../dispatchFunctions";
import {
  addUserService,
  getUserListService,
  getUsersByReportingAuthority,
  updateUserStatusService,
} from "./userService";
import {
  ADD_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE,
  GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST,
  GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS,
  GET_USER_LIST_FAILURE,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  UPDATE_USER_STATUS_FAILURE,
  UPDATE_USER_STATUS_REQUEST,
  UPDATE_USER_STATUS_SUCCESS,
} from "../actionTypes";
import { updateMasterData } from "../masterData/masterDataAction";
import {
  ADD_DATA_IN_MASTER,
  MASTER_TYPE,
  UPDATE_DATA_STATUS_IN_MASTER,
} from "../masterData/masterDataReducer";
import { GetErrorMessage } from "../stateHelperFunction";

export const addUserAction = (data: addUserData, successCallback: Function) => {
  return async (dispatch: any) => {
    dispatch(request(ADD_USER_REQUEST));

    await addUserService(data).then(
      (result: any) => {
        dispatch(success(ADD_USER_SUCCESS, result));
        showToastMessageSuccess("User added");
        successCallback();
        if (result.data.data.records.Roles.includes("Admin")) {
          dispatch(
            updateMasterData(ADD_DATA_IN_MASTER, {
              masterType: MASTER_TYPE.ADMIN,
              data: result.data.data.records,
            })
          );
        } else if (
          result.data.data.records.Roles.includes("Team Leader") &&
          !result.data.data.records.Roles.includes("Admin")
        ) {
          dispatch(
            updateMasterData(ADD_DATA_IN_MASTER, {
              masterType: MASTER_TYPE.TEAM_LEADER,
              data: result.data.data.records,
            })
          );
        } else {
          dispatch(
            updateMasterData(ADD_DATA_IN_MASTER, {
              masterType: MASTER_TYPE.ARTICLE,
              data: result.data.data.records,
            })
          );
        }
      },
      (error: any) => {
        dispatch(
          failure(ADD_USER_FAILURE, GetErrorMessage(error?.response?.data))
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
      }
    );
  };
};

export const getUserList = (searchObj: searchParams) => {
  return async (dispatch: any) => {
    dispatch(request(GET_USER_LIST_REQUEST));

    await getUserListService(searchObj).then(
      (result: any) => dispatch(success(GET_USER_LIST_SUCCESS, result.data)),
      (error: any) =>
        dispatch(
          failure(GET_USER_LIST_FAILURE, GetErrorMessage(error?.response?.data))
        )
    );
  };
};

export const getUsersByReportingAuthorityList = (
  searchObj: searchParams,
  isActive: boolean
) => {
  return (dispatch: any) => {
    dispatch(request(GET_USERS_BY_REPORTING_AUTHORITY_LIST_REQUEST));
    getUsersByReportingAuthority(searchObj, isActive).then(
      (result: any) =>
        dispatch(
          success(GET_USERS_BY_REPORTING_AUTHORITY_LIST_SUCCESS, result.data)
        ),
      (error: any) =>
        dispatch(
          failure(
            GET_USERS_BY_REPORTING_AUTHORITY_LIST_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        )
    );
  };
};

export const updateUserStatus = (
  userId: number,
  activeStatus: boolean,
  successCallback: Function
) => {
  return (dispatch: any) => {
    dispatch(request(UPDATE_USER_STATUS_REQUEST));

    updateUserStatusService(userId).then(
      (result: any) => {
        dispatch(success(UPDATE_USER_STATUS_SUCCESS, result.data));
        showToastMessageSuccess("User status updated");
        successCallback && successCallback();
        if (result.data.data.records.Roles.includes("Admin")) {
          dispatch(
            updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
              masterType: MASTER_TYPE.ADMIN,
              data: result.data.data.records,
            })
          );
        } else if (
          !result.data.data.records.Roles.includes("Admin") &&
          result.data.data.records.Roles.includes("Team Leader")
        ) {
          dispatch(
            updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
              masterType: MASTER_TYPE.TEAM_LEADER,
              data: result.data.data.records,
            })
          );
        } else {
          dispatch(
            updateMasterData(UPDATE_DATA_STATUS_IN_MASTER, {
              masterType: MASTER_TYPE.ARTICLE,
              data: result.data.data.records,
            })
          );
        }
      },
      (error: any) => {
        dispatch(
          failure(
            UPDATE_USER_STATUS_FAILURE,
            GetErrorMessage(error?.response?.data)
          )
        );
        showToastMessageFailure(GetErrorMessage(error?.response?.data));
        successCallback && successCallback();
      }
    );
  };
};
