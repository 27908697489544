import Avatar from "app/components/commonComponent/Avatar";
import ImageDisplay from "app/components/commonComponent/ImageDisplay";
import Table from "app/components/commonComponent/table/Table";
import { PAYMENT_STATUS } from "app/helpers/constants";
import { convert, getColor, getStatus } from "app/helpers/helperFunction";
import { CLIENT_REPORT_DETAILS } from "app/helpers/routes";
import { FC } from "react";
import { Link, useParams } from "react-router-dom";

interface props {
  taskArr: any;
}

const HEADERS = [
  {
    columnName: "Task Code",
    id: "TaskCode",
    className: "min-w-125px ps-2",
  },
  {
    columnName: "Task",
    id: "TypeName",
    className: "min-w-125px",
  },
  {
    columnName: "Start Date",
    id: "StartDate",
    className: "min-w-125px",
  },
  {
    columnName: "Due Date",
    id: "DueDate",
    className: "min-w-125px",
  },
  {
    columnName: "Article",
    id: "PersonAssigned",
    className: "min-w-125px",
  },
  {
    columnName: "Managed By",
    id: "ManagerName",
    className: "min-w-125px",
  },
  {
    columnName: "Billing Status",
    id: "BillingStatus",
    className: "min-w-125px",
  },
  {
    columnName: "Status",
    id: "StatusValue",
    className: "min-w-125px",
  },
];

const ClientReportTaskDetails: FC<props> = ({ taskArr }) => {
  const params = useParams();

  return (
    <div className="card">
      <div className="card-header pt-5 align-items-center border-0">
        <div className="card-title flex-column">
          <div className="fw-bold">Tasks Details</div>
        </div>
      </div>
      <div className="">
        <Table
          checkBoxColumn={{ show: false }}
          error={""}
          headers={HEADERS}
          success={true}
          loading={false}
          paginate={{
            show: false,
          }}
          tableData={taskArr}
          tableRow={(row) => (
            <tr key={row.Id}>
              <td className="text-dark fw-bold ps-2">
                <Link
                  to={`${CLIENT_REPORT_DETAILS}/${params.clientId}/task/${row.Id}`}
                  className="text-gray-800 text-hover-primary fw-bold ps-1"
                >
                  {row.TaskCode}
                </Link>
                {row.IsOverdue && (
                  <div className="d-flex align-items-center text-gray-500 fs-7">
                    <ImageDisplay
                      path="/media/task/overdue_flag.png"
                      errorPath="/media/task/overdue_flag.png"
                      altText="flag"
                      className="w-15px"
                    />
                    <div className="text-danger w-100px">Overdue</div>
                  </div>
                )}
              </td>
              <td>{row.TypeName}</td>
              <td>{row.StartDate ? convert(row.StartDate) : "-"}</td>
              <td>{row.DueDate ? convert(row.DueDate) : "-"}</td>
              <td>
                {row.PersonAssigned ? (
                  <div className="d-flex">
                    <div className="min-w-150px align-items-center bg-light border d-flex fs-7 fw-semibold py-1 px-2 rounded-pill text-gray-600 min-w-125px">
                      <div className="w-20px me-3">
                        <Avatar
                          firstName={row.PersonAssigned}
                          lastName={""}
                          size={20}
                          color={getColor(row.PersonAssigned).color}
                          background={getColor(row.PersonAssigned).background}
                          length={1}
                          rounded={true}
                        />
                      </div>
                      {row.PersonAssigned}
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td>
                {row.ManagerName ? (
                  <div className="d-flex">
                    <div className="min-w-150px d-flex align-items-center bg-light border fs-7 fw-semibold py-1 ps-2 pe-4 rounded-pill text-gray-600">
                      <div className="w-20px me-3">
                        <Avatar
                          firstName={row.ManagerName}
                          lastName={""}
                          size={20}
                          color={getColor(row.ManagerName).color}
                          background={getColor(row.ManagerName).background}
                          length={1}
                          rounded={true}
                        />
                      </div>
                      <span className="w-auto">{row.ManagerName}</span>
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td>
                <span className={`badge bill-status-${row.BillingStatus}`}>
                  {PAYMENT_STATUS[row.BillingStatus]}
                </span>
              </td>
              <td>
                <span className={`badge status-${row.StatusValue}`}>
                  {getStatus(row.StatusValue).name}
                </span>
              </td>
            </tr>
          )}
        />
      </div>
    </div>
  );
};

export default ClientReportTaskDetails;
