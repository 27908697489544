import { connect } from "react-redux";
import {
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image as ReactPdfImage,
  Font,
  usePDF,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { FC, useEffect } from "react";

import { convert, getFormData } from "app/helpers/helperFunction";
import Inter from "../../../../fonts/Inter/static/Inter-Regular.ttf";
import InterMedium from "../../../../fonts/Inter/static/Inter-Medium.ttf";
import InterSemiBold from "../../../../fonts/Inter/static/Inter-SemiBold.ttf";
import InterBold from "../../../../fonts/Inter/static/Inter-Bold.ttf";
import InterExtraBold from "../../../../fonts/Inter/static/Inter-ExtraBold.ttf";
import { saveBillPdf } from "app/reducers/billing/billingAction";
import { useAuth } from "../../auth";
import { underline } from "pdfkit";

export interface billTasks {
  TaskCode: string;
  ClientName: string;
  TypeName: string;
  CategoryName: string;
  Fees: number;
  BillId: number;
  Id: number;
}

export interface billPayments {
  Amount: number;
  Narration: string;
  TaskCode: string;
}

interface billData {
  base64Qr: string;
  Date: string;
  TotalAmountWithoutTax: number;
  TotalTaxAmount: number;
  TotalAmount: number;
  BillNumber: string;
  ClientName: string;
  Client_GSTIN: string;
  Tasks: Array<billTasks>;
  Payments: Array<billPayments>;
  Receipts: Array<billPayments>;
  Firm: {
    Address: string;
    IFSCCode: string;
    BankName: string;
    AccountNumber: string;
    Name: string;
    PANNumber: string;
    GSTIN: string;
    QRCodeFile: {
      FileName: string;
      FilePath: string;
      FileUrl: string;
      MimeType: string;
    };
  };
  Id: number;
}

interface props {
  billData: billData;
  saveBillPdfDispatch: Function;
}

interface generatePdfClickProps {
  billData: billData;
  title: string;
  showIcon?: boolean;
  className: string;
}

Font.register({
  family: "Inter",
  fonts: [
    { src: Inter, fontWeight: 400 },
    { src: InterMedium, fontWeight: 500 },
    {
      src: InterSemiBold,
      fontWeight: 600,
    },
    {
      src: InterBold,
      fontWeight: 700,
    },
    {
      src: InterExtraBold,
      fontWeight: 800,
    },
  ],
  format: "truetype",
});

interface formatedBillProps {
  title?: string;
  companyName: string | undefined;
  billData: {
    base64Qr: string;
    Date: string;
    TaxRate?: number;
    TotalAmountWithoutTax: number;
    TotalTaxAmount: number;
    TotalAmount: number;
    BillNumber: string;
    ClientName: string;
    Client_GSTIN: string;
    Tasks: Array<billTasks>;
    Payments: Array<billPayments>;
    Receipts: Array<billPayments>;
    Firm: {
      Address: string;
      IFSCCode: string;
      BankName: string;
      AccountNumber: string;
      Name: string;
      PANNumber: string;
      GSTIN: string;
      QRCodeFile: {
        FileName: string;
        FilePath: string;
        FileUrl: string;
        MimeType: string;
      };
    };
    Id: number;
  };
}

const GetBillPdf: FC<formatedBillProps> = ({
  billData,
  title,
  companyName,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.pageContainer}>
          <View
            style={{
              borderLeft: "1px solid #cccccc",
              borderRight: "1px solid #cccccc",
              borderTop: "1px solid #cccccc",
            }}
          >
            <View style={styles.companyName}>
              <Text>{companyName}</Text>
            </View>
            <View style={styles.CA}>
              <Text>Chartered Accountants</Text>
            </View>
            <View style={styles.firmDetails}>
              {/* <ReactPdfImage
                style={styles.CALogo}
                src="/media/auth/logo_512.png"
              ></ReactPdfImage> 
              <View style={styles.firmAndYear}>
                <View style={styles.firmName}>
                  <Text>{billData?.Firm?.Name || "-"}</Text>
                </View>
                <View style={styles.financialYear}>
                  <Text>
                    FY : {new Date().getFullYear()}-
                    {+String(new Date().getFullYear()).slice(-2) + 1}
                  </Text>
                </View>
              </View> */}
            </View>
            <View style={styles.company}>
              <View style={styles.companyDetails}>
                <View style={styles.firmDetails}>
                  <Text style={styles.singleCompanyDetails}>
                    <Text style={styles.textBold}>Address : </Text>{" "}
                    {billData?.Firm?.Address || "-"}
                  </Text>
                  <Text style={styles.singleCompanyDetails}>
                    <Text style={styles.textBold}>GSTIN : </Text>{" "}
                    {billData?.Firm?.GSTIN || "-"}
                  </Text>
                </View>
                {/* {space} */}
                <Text style={styles.invoiceNumber}>
                  <Text style={styles.textBold}>
                    {title === "Proforma Invoice" ? "Proforma Invoice No. : " : "Invoice No. : "} 
                  </Text>
                  <Text>{new Date().getFullYear()}-
                  {+String(new Date().getFullYear()).slice(-2) + 1}/
                  {billData?.BillNumber || "-"}</Text>
                </Text>
                <View style={styles.clientDetail}>
                  <Text style={styles.lastCompanyDetails}>
                    <Text style={styles.textBold}>Bill To : </Text>
                    {billData?.ClientName || "-"}
                  </Text>
                  <Text style={styles.lastCompanyDetails}>
                    <Text style={styles.textBold}>GSTIN : </Text>
                    {billData.Client_GSTIN || "-"}
                  </Text>
                </View>
              </View>
              <View style={styles.billDetails}>
                {/* <Text style={styles.billNumber}>
                  <Text style={styles.textBold}>Bill No. : </Text>
                  {billData?.BillNumber || "-"}
                </Text> */}
                <Text style={styles.billDate}>
                  <Text style={styles.textBold}>Date : </Text>
                  {billData?.Date ? convert(billData.Date) : "-"}
                </Text>
              </View>
            </View>
            <View style={styles.InvoiceTitle}>
              <Text>{title ? title : "INVOICE"}</Text>
            </View>
            <View style={styles.tableContainer}>
              <View style={styles.headerContainer}>
                <Text style={styles.tableDescPart}>Particulars</Text>
                <Text style={styles.tableAmountPart}>Rs.</Text>
              </View>
              <View>
                {billData?.Tasks?.map((task, index: number) => (
                  <View style={styles.singleTableRow} key={index} wrap={false}>
                    <Text style={styles.tableDescPart}>{task.TypeName}</Text>
                    <Text
                      style={[styles.tableAmountPart, styles.bodyAmountPart]}
                    >
                      {task.Fees}
                    </Text>
                  </View>
                ))}
                {(billData?.Payments?.length !== 0 ||
                  billData?.Receipts?.length !== 0) && (
                  <View style={styles.singleTableRow} wrap={false}>
                    <Text
                      style={[styles.reimbursementTitle, styles.headerContainer]}
                    >
                      Reimbursement Expenses
                    </Text>
                    {/* <Text
                      style={[styles.tableAmountPart, styles.bodyAmountPart]}
                    ></Text> */}
                  </View>
                )}
                {billData?.Payments?.map((payment, index: number) => (
                  <View style={styles.singleTableRow} key={index} wrap={false}>
                    <Text style={styles.tableDescPart}>
                      {payment.Narration || "Task Fees"}
                    </Text>
                    <Text
                      style={[styles.tableAmountPart, styles.bodyAmountPart]}
                    >
                      {payment.Amount}
                    </Text>
                  </View>
                ))}
                {billData?.Receipts?.map((receipt, index: number) => (
                  <View style={styles.singleTableRow} key={index} wrap={false}>
                    <Text style={styles.tableDescPart}>
                      {receipt.Narration || "Task Fees"}
                    </Text>
                    <Text
                      style={[styles.tableAmountPart, styles.bodyAmountPart]}
                    >
                      - {receipt.Amount}
                    </Text>
                  </View>
                ))}
                <View style={styles.tableTotalRow} wrap={false}>
                  <Text
                    style={[
                      styles.tableDescPart,
                      styles.tableTotalDesc,
                      styles.footerContainer,
                    ]}
                  >
                    SubTotal
                  </Text>
                  <Text
                    style={[
                      styles.tableAmountPart,
                      styles.bodyAmountPart,
                      styles.tableTotalAmount,
                      styles.footerContainer,
                    ]}
                  >
                    {billData?.TotalAmountWithoutTax || "0"}
                  </Text>
                </View>
                <View style={styles.tableTotalRow} wrap={false}>
                  <Text style={[styles.tableDescPart, styles.tableTotalDesc]}>
                    Add:   GST @ {billData?.TaxRate}%
                  </Text>
                  <Text
                    style={[
                      styles.tableAmountPart,
                      styles.bodyAmountPart,
                      styles.tableTotalAmount,
                    ]}
                  >
                    {billData?.TotalTaxAmount || "0"}
                  </Text>
                </View>
                <View style={styles.tableTotalRow} wrap={false}>
                  <Text
                    style={[
                      styles.tableDescPart,
                      styles.tableTotalDesc,
                      styles.footerContainer,
                    ]}
                  >
                    Total Amount
                  </Text>
                  <Text
                    style={[
                      styles.tableAmountPart,
                      styles.bodyAmountPart,
                      styles.tableTotalAmount,
                      styles.footerContainer,
                    ]}
                  >
                    {billData?.TotalAmount || "0"}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              borderLeft: "1px solid #cccccc",
              borderRight: "1px solid #cccccc",
              borderBottom: "1px solid #cccccc",
            }}
          >
            <View style={styles.bankDetails} wrap={false}>
              <View style={styles.bankDetailsTitle}>
                <Text style={[styles.textBold, styles.textUnderline]}>
                  Bank Details : 
                </Text>
              </View>
              <View style={styles.bankAccountDetailsContainer}>
                <View>
                  <Text style={styles.bankAccountDetails}>
                    {billData?.Firm?.Name || "-"}
                  </Text>
                  <Text style={styles.bankAccountDetails}>
                    {billData?.Firm?.BankName || "-"}
                    {/* + Branch but we are not taking bank branch of firm */}
                  </Text>
                  <Text style={styles.bankAccountDetails}>
                    Current A/c. No. {billData?.Firm?.AccountNumber || "-"}
                  </Text>
                  <Text style={styles.bankAccountDetails}>
                    IFSC : {billData?.Firm?.IFSCCode || "-"}
                  </Text>
                  {/* UPI ID : but not in form feild of firm */}
                </View>
                <View>
                  <ReactPdfImage
                    src={`data:image/${billData?.Firm?.QRCodeFile?.MimeType};base64,${billData?.base64Qr}`}
                    style={styles.qrCodeImage}
                  />
                </View>
              </View>
              <View style={styles.bankDetailsNote}>
                <Text>
                  Please Transfer the amount to our following Bank Account or
                  Send a Cheque
                </Text>
              </View>
            </View>
            <View style={styles.adminDetailsContainer} wrap={false}>
              <Text style={styles.adminDetail}>
                For {billData?.Firm?.Name || "-"}
              </Text>
              <Text style={styles.signSectionText}>Chartered Accountants</Text>
              <ReactPdfImage
                style={styles.adminSign}
                src="/media/task/adminSign.png"
              />
              <Text style={styles.adminDetail}>(Kalpesh Parekh)</Text>
              <Text style={[styles.signSectionText]}>Partner</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const GetUrlForPdf: FC<props> = ({ billData, saveBillPdfDispatch }) => {
  const { currentUser } = useAuth();
  const companyName = currentUser?.CompanyName;

  const [instance] = usePDF({
    document: <GetBillPdf billData={billData} companyName={companyName} />,
  });

  useEffect(() => {
    if (instance.blob) {
      saveBillPdfDispatch(getFormData({ file: instance.blob }), billData.Id);
      // getBase64String(instance.blob, billData.Id)
    }
  }, [instance.blob]);

  return <></>;
};

export const GeneratePdfByClick: FC<generatePdfClickProps> = ({
  billData,
  title,
  showIcon,
  className,
}) => {
  console.log(billData, "BILL DATA");

  const { currentUser } = useAuth();
  const companyName = currentUser?.CompanyName;

  return (
    <PDFDownloadLink
      document={
        <GetBillPdf
          billData={billData}
          title={title}
          companyName={companyName}
        />
      }
      fileName={`${billData.BillNumber}.pdf`}
    >
      {() => {
        return (
          <span className={className} title="Download">
            {showIcon ? (
              <i className="fa-solid fa-download"></i>
            ) : (
              <>
                <i className="fa-solid fa-download"></i> Download Pdf
              </>
            )}
          </span>
        );
      }}
    </PDFDownloadLink>
  );
};

export const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontFamily: "Inter",
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 33,
    fontSize: 11,
    lineHeight: 1,
    flexDirection: "column",
    color: "#414456",
  },
  pageContainer: {
    paddingBottom: 10,
    //border: "1px solid red",
  },
  textBold: {
    fontWeight: "bold",
  },
  textUnderline: {
    textDecoration: "underline",
  },

  InvoiceTitle: {
    fontSize: 10,
    fontWeight: 600,
    paddingVertical: 9.75,
    textAlign: "center",
    textDecoration: "underline",
  },
  firmDetails: {
    marginVertical: 10,
  },
  invoiceNumber: {
    paddingLeft: 10,
    marginTop: 20,
    marginBottom: 13,
  },
  clientDetail: {
    marginVertical: 10
  },
  CALogo: {
    width: 70,
    paddingRight: 19.5,
    paddingLeft: 13,
    paddingVertical: 5,
    //borderRight: "1px solid #cccccc",
  },
  firmAndYear: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  firmName: {
    paddingLeft: 13,
    paddingVertical: 5,
    fontSize: 15,
    flexDirection: "row",
    fontWeight: 500,
    alignItems: "center",
    width: "75%",
    //borderRight: "1px solid #cccccc",
  },
  financialYear: {
    fontSize: 10,
    fontWeight: 500,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "25%",
  },
  company: {
    //borderTop: "3px solid #cccccc",
    flexDirection: "row",
    fontSize: 10,
    fontWeight: 500,
  },
  companyDetails: {
    width: "75%",
    //borderRight: "1px solid #cccccc",
  },
  companyName: {
    fontSize: 25,
    fontWeight: 600,
    color: "#01559D",
    paddingLeft: "2px",
    marginBottom: "5px",
  },
  CA: {
    fontSize: 15,
    fontWeight: 600,
    color: "#01559D",
    paddingLeft: "2px",
  },
  billDetails: {
    // marginVerticle: 30,
    // paddingright: 10,
    // textAlign: "right",

    width: "25%",
  },
  singleCompanyDetails: {
    //borderBottom: "1px solid #cccccc",
    paddingVertical: 6,
    paddingLeft: 10,
  },
  lastCompanyDetails: {
    paddingVertical: 5,
    paddingLeft: 10,
  },
  billDate: {
    marginTop: 80,
    textAlign: "right",
    paddingRight: 10
  },
  billNumber: {
    //borderBottom: "1px solid #cccccc",
    paddingVertical: 12,
    paddingLeft: 10,
  },
  tableContainer: {
    //borderTop: "3px solid #cccccc",
    //borderBottom: "1px solid #cccccc",
  },
  tableDescPart: {
    width: "85%",
    //borderRight: "1px solid #cccccc",
    paddingVertical: 4,
    paddingLeft: 10,
    //backgroundColor: "#cccccc"
  },
  tableAmountPart: {
    width: "15%",
    paddingVertical: 4,
    paddingRight: 10,
    //backgroundColor: "#cccccc"
  },
  headerContainer: {
    flexDirection: "row",
    textAlign: "center",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: "#cccccc",
    marginVertical: "15px",
    // borderBottom: "1px solid #cccccc",
  },
  footerContainer: {
    flexDirection: "row",
    textAlign: "right",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: "#cccccc",
    marginVertical: "15px",
    // borderBottom: "1px solid #cccccc",
  },
  singleTableRow: {
    flexDirection: "row",
    //borderTop: "1px solid #cccccc",
    fontSize: 10,
  },
  bodyAmountPart: {
    textAlign: "right",
  },
  reimbursementTitle: {
    width: "100%",
    textAlign: "center",
    fontSize: 10,
    fontWeight: 500,
    paddingVertical: 4,
  },
  tableTotalRow: {
    //borderTop: "1px solid #cccccc",
    // borderBottom: "3px solid #cccccc",
    flexDirection: "row",
  },
  tableTotalDesc: {
    textAlign: "right",
    fontSize: 10,
    fontWeight: 600,
    paddingRight: 10,
  },
  tableTotalAmount: {
    fontSize: 10,
    fontWeight: 600,
  },
  bankDetails: {
    //borderTop: "1px solid #cccccc",
  },
  bankDetailsTitle: {
    paddingLeft: 10,
    fontSize: 10,
    fontWeight: 500,
    paddingVertical: 8,
  },
  bankAccountDetailsContainer: {
    //borderTop: "1px solid #cccccc",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 5,
  },
  bankAccountDetails: {
    fontSize: 10,
    fontWeight: 500,
    paddingVertical: 2,
    paddingHorizontal: 10,
  },
  qrCodeImage: {
    width: 90,
    paddingRight: 10,
    //paddingTop: 8,
  },
  bankDetailsNote: {
    fontSize: 10,
    fontWeight: 500,
    paddingHorizontal: 10,
    paddingTop: 5,
    paddingBottom: 3,
  },
  adminDetailsContainer: {
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    paddingTop: 10,
    paddingHorizontal: 10,
    //borderTop: "1px solid #cccccc",
  },
  adminDetail: {
    fontSize: 10,
    fontWeight: "bold"
  },
  adminSign: {
    width: 90,
  },
  signSectionText: {
    paddingVertical: 3,
  }
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    saveBillPdfDispatch: (pdfData: FormData, billId: number) =>
      dispatch(saveBillPdf(pdfData, billId)),
  };
};

export default connect(null, mapDispatchToProps)(GetUrlForPdf);
